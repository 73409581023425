<template>

</template>

<script>
    import { Howl } from 'howler'

    export default {    
        data() {
            return {
                fallSound: null,
                playFall: false
            }
        },    
        created: function() {            
            this.fallSound = new Howl({
                src: window.isandroid ? 'https://myisa.mintt.app/sounds/fall.mp3' : '/sounds/fall.mp3',
                autoplay: false,
                autoUnlock: true,
                loop: true,
                volume: 0.5,
                onend: function() {
                    console.log('Finished!');
                }
            });
        },
        mounted(){
            if(this.$store.state.fleet.hasAlert && !this.playFall) {
                this.fallSound.play()
                this.playFall = true;
            }
        },
        watch: {
            '$store.state.fleet.hasAlert': function(val) {                                        
                    console.log('$store.state.fleet.hasAlert ==> ' + val);                                   
                    if(val && !this.playFall)
                    {   console.log('starting sound')
                        this.fallSound.play()
                        this.playFall = true;
                    }
                    if(!val && this.playFall) {
                        console.log('stopping sound')
                        this.fallSound.stop();
                        this.playFall = false;
                    }
                                        
            }            
        }
    }
</script>